import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';

import * as sectionStyles from '../../sections/index.styles';

export const arrowContainerStyle = css`
  ${sectionStyles.horizontalSectionArrowContainerStyle};
  align-items: flex-start;
  & > button {
    margin-top: 110px;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      & > button {
        margin-top: 67px;
      }
    `,
  )};
`;

export const containerStyle = sectionStyles.horizontalSectionScrollContainerStyle;
export const listStyle = css`
  ${sectionStyles.horizontalSectionScrollContainerListStyle};

  display: flex;
`;

export const itemStyle = (margin: number): SerializedStyles => css`
  margin-right: ${margin}px;

  &:last-of-type {
    margin-right: 0;
  }
`;
