import { css } from '@emotion/react';
import { BreakPoint, orBelow, RIDITheme } from '@ridi-web/design-system/Styles';

import { fontFamily } from '@/components/styles/reset';
import { rgba } from '@/utils/rgba';

// TODO: 정식 섹션으로 승격되면 exp-ridi-roboto-bold-italic를 기존 ridi-roboto에 편입시키고, 아래 expFontFamily를 제거해야함.
const expFontFamily = fontFamily
  .split(', ')
  .map(font => (font === 'ridi-roboto' ? 'exp-ridi-roboto-bold-italic' : font))
  .join(', ');

export const rankStyle = css`
  position: absolute;
  top: -125px;
  left: -9px;

  font-weight: 700;
  font-style: italic;
  font-size: 90px;
  pointer-events: none;
  font-family: ${expFontFamily};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      top: -120px;
      left: -9px;
      font-size: 60px;
    `,
  )}
`;

export const svgStyle = css`
  display: block;
`;

export const strokeStyle = (theme: RIDITheme) => css`
  stroke: ${theme.colors.white};
  stroke-width: 5px;
  text-shadow: 0px 0.5px 0.5px ${rgba(theme.colors.black, 0.15)};
`;

export const metadataStyle = css`
  position: relative;
`;
