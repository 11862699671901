import { css } from '@emotion/react';

import * as sectionStyles from '@/components/genreHome/sections/index.styles';
import { BreakPoint, orBelow } from '@/components/styles/media';

// column
export const GRID_ORDERED_LAYOUT_COLUMN_DESKTOP_WIDTH = 370;
export const GRID_ORDERED_LAYOUT_COLUMN_MOBILE_WIDTH = 337;

// item
const GRID_ORDERED_LAYOUT_ITEM_DESKTOP_WIDTH = GRID_ORDERED_LAYOUT_COLUMN_DESKTOP_WIDTH;
const GRID_ORDERED_LAYOUT_ITEM_DESKTOP_HEIGHT = 116;
const GRID_ORDERED_LAYOUT_ITEM_MOBILE_WIDTH = GRID_ORDERED_LAYOUT_COLUMN_MOBILE_WIDTH;
const GRID_ORDERED_LAYOUT_ITEM_MOBILE_HEIGHT = 101;

export const arrowContainerStyle = css`
  ${sectionStyles.horizontalSectionArrowContainerStyle};
`;

export const itemStyle = css`
  width: ${GRID_ORDERED_LAYOUT_ITEM_DESKTOP_WIDTH}px;
  height: ${GRID_ORDERED_LAYOUT_ITEM_DESKTOP_HEIGHT}px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: ${GRID_ORDERED_LAYOUT_ITEM_MOBILE_WIDTH}px;
      height: ${GRID_ORDERED_LAYOUT_ITEM_MOBILE_HEIGHT}px;
    `,
  )};
`;

export const resetOlStyle = css`
  list-style: none;

  li:not(:nth-of-type(3n)) {
    margin-bottom: 6px;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      li:not(:nth-of-type(3n)) {
        margin-bottom: 8px;
      }
    `,
  )};
`;
