import { css } from '@emotion/react';

import { displayNoneForTouchDevice, scrollBarHidden, ZIndexLayer } from '@/components/styles';
import { BreakPoint, greaterThan, touchDevice } from '@/components/styles/media';

export const sliderContainerStyle = css`
  position: relative;
`;

export const slideFrameStyle = css`
  display: flex;
  width: 100%;
  overflow: hidden;
  ${scrollBarHidden};
  ${touchDevice(css`
    overflow: visible;
  `)};
`;

export const listWrapperStyle = css`
  display: flex;
  transform: translate(0);
`;

export const interpolateFrameStyle = css`
  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      transition: transform 0.03333333s linear;
    `,
  )}
`;

export const arrowContainerStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  z-index: ${ZIndexLayer.LEVEL_DROPDOWN - 1};
  ${displayNoneForTouchDevice}

  &[data-is-hydrated="false"] * {
    opacity: 0;
    pointer-events: none;
  }
`;
