import { useMemo } from 'react';

import { HorizontalScrollContainer, snapBehavior } from '@/components/common/HorizontalScrollContainer';
import { BreakPoint } from '@/components/styles/media';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';

import { constants } from '../GenreHomeBook';
import { useSectionTrackingDataContext } from '../SectionTrackingDataContextProvider/SectionTrackingDataContextProvider';
import * as styles from './ListLayoutSection.styles';

const getDefaultItemMargin = (isMobile: boolean, itemMargin?: number) => {
  if (itemMargin !== undefined) {
    return itemMargin;
  }
  if (isMobile) {
    return 8;
  }
  return 6;
};

type ListLayoutSectionItemBaseType = {
  id: number;
};

interface ListLayoutSectionProps<T> {
  className?: string;
  items: T[];
  itemMargin?: number;
  itemSizeMobile?: number;
  itemSizeDesktop?: number;
  renderItem: (props: { item: T; index: number }) => ReactJSX.Element | null;
}

/**
 * @description List 형태로 렌더링 하는 섹션 컴포넌트
 * @param {T[]} items item은 필수적으로 id를 가지고 있어야 한다
 * @param {(props: { item: T; index: number }) => ReactJSX.Element} renderItem item을 rendering할 함수
 * @param {number} itemMargin item 사이의 간격 (px), default: 6
 * @param {number} itemSizeMobile 모바일에서의 아이템 크기 (px), default: 120
 * @param {number} itemSizeDesktop 데스크탑에서의 아이템 크기 (px), default: 180
 * @example
 * const MySection = () => <ListLayoutSection items={items} renderItem={renderItem} />;
 */

export const ListLayoutSection = <T extends ListLayoutSectionItemBaseType>({
  className,
  items,
  itemMargin,
  itemSizeMobile = constants.MOBILE_BOOK_DEFAULT_COVER_WIDTH,
  itemSizeDesktop = constants.DESKTOP_BOOK_DEFAULT_COVER_WIDTH,
  renderItem,
}: ListLayoutSectionProps<T>): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const isMobile = useResponsiveIsBelow(BreakPoint.DesktopSmall, false);
  const itemSize = isMobile ? itemSizeMobile : itemSizeDesktop;
  const defaultItemMargin = getDefaultItemMargin(isMobile, itemMargin);
  const scrollBehavior = useMemo(
    () => snapBehavior(itemSize, { gutterSize: defaultItemMargin }),
    [itemSize, defaultItemMargin],
  );

  const itemStyle = useMemo(() => styles.itemStyle(defaultItemMargin), [defaultItemMargin]);

  return (
    <HorizontalScrollContainer
      css={styles.containerStyle}
      className={className}
      arrowContainerCss={styles.arrowContainerStyle}
      scrollBehavior={scrollBehavior}
      eventParams={sectionTrackingData}>
      <ul css={styles.listStyle}>
        {items.map((item, index) => (
          <li key={item.id} css={itemStyle}>
            {renderItem({ item, index })}
          </li>
        ))}
      </ul>
    </HorizontalScrollContainer>
  );
};
