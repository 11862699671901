import { SerializedStyles } from '@emotion/react';
import { Children, MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';

import { ArrowButton } from '@/components/common/ArrowButton';
import { SwipeTweenContext, SwipeTweenSpace, SwipeTweenTag, useSwipeWithTween } from '@/components/genreHome/hooks';
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';
import { useLatestRef } from '@/hooks/useLatestRef';
import { TweenDelta, TweenNormalizedPosition } from '@/hooks/useTween';
import { easeInOutQuad, easeOutCubic, easeOutQuad } from '@/utils/easing';
import { EventParamsType, sendClickEvent, sendScrollEvent } from '@/utils/eventClient';
import { debounce, throttle } from '@/utils/functions';

import * as styles from './Slider.styles';

/*
 * TweenSpace
 */
const OVERSCROLL_STIFFNESS = 12;
const getOverscrollAmount = (position: number, sliderLength: number) =>
  position <= 0 ? position : Math.max(0, position - sliderLength);

const createSliderTweenSpace = (
  sliderLengthRef: MutableRefObject<number>,
  maxSwipeRef: MutableRefObject<number>,
): SwipeTweenSpace => {
  /*
   * TweenSpace::normalize
   */
  const normalize = (position: number) =>
    Math.max(-1, Math.min(position, sliderLengthRef.current + 1)) as TweenNormalizedPosition;

  /*
   * TweenSpace::diff
   */
  const diff = (from: TweenNormalizedPosition, to: number, tag: SwipeTweenTag) => {
    // Disable overscroll on touchpad
    const shouldApplyOverscroll = !('type' in tag) || tag.type === 'touch';

    // Make overscroll stiff
    const overscrollAmount = getOverscrollAmount(to, sliderLengthRef.current);
    const overscrollAmountApplied = shouldApplyOverscroll
      ? Math.sqrt(Math.abs(overscrollAmount) / OVERSCROLL_STIFFNESS) * Math.sign(overscrollAmount)
      : 0;

    // Calculate delta
    return (to - from - overscrollAmount + overscrollAmountApplied) as TweenDelta;
  };

  /*
   * TweenSpace::snap
   */
  const snap = (position: number, tag: SwipeTweenTag) => {
    if (tag.name !== 'snap') {
      return position as TweenNormalizedPosition;
    }

    const flooredLength = Math.floor(sliderLengthRef.current);
    const remainder = sliderLengthRef.current - flooredLength;
    let nextPosition = position;

    if (tag.initial >= flooredLength && remainder > 0) {
      const maxSwipe = maxSwipeRef.current - 1 + remainder;
      nextPosition = tag.initial + Math.max(-maxSwipe, Math.min(tag.offset, maxSwipe));
    }

    if (nextPosition <= flooredLength) {
      return Math.max(0, Math.round(nextPosition)) as TweenNormalizedPosition;
    }

    // Remainder Case
    // > If the range is 0 ~ 0.6, 0.4 should be rounded to 0.6
    if (nextPosition - flooredLength < remainder * 0.5) {
      return flooredLength as TweenNormalizedPosition;
    }

    return sliderLengthRef.current as TweenNormalizedPosition;
  };

  return { normalize, diff, snap };
};

/*
 * Slider
 */
interface SliderProps {
  arrowContainerCss?: SerializedStyles;
  contentContainerCss?: SerializedStyles;
  // 마지막 아이템까지 이동 시, 스크롤 최대 이동 영역을 slider 최우측으로 제한
  limitScrollAreaToRightSide?: boolean;
  className?: string;
  children: ReactNode;
  eventParams?: {
    params?: EventParamsType;
    screenName: string;
  };
}

export const Slider = ({
  arrowContainerCss,
  contentContainerCss,
  limitScrollAreaToRightSide,
  className,
  children,
  eventParams,
}: SliderProps): ReactJSX.Element => {
  const itemLength = Children.count(children);

  /*
   * Element References
   */
  const frameRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLUListElement>(null);

  /*
   * Metrics References
   */
  const frameWidthRef = useRef(0);
  const wrapperWidthRef = useRef(0);
  const itemWidthRef = useRef(0);

  /*
   * Variable & Derived References
   */
  const indexRef = useRef(0);
  const itemPerFrameRef = useRef(0);
  const maxSwipeRef = useRef(0);
  const sliderLengthRef = useRef(0);

  /*
   * Handle Item Updates
   */
  const buttonsRef = useRef<HTMLDivElement | null>(null);
  const leftButtonRef = useRef<HTMLButtonElement | null>(null);
  const rightButtonRef = useRef<HTMLButtonElement | null>(null);

  const updateArrowButton = useCallback(() => {
    const EPSILON = 1e-4;

    const isLeftDisabled = indexRef.current <= EPSILON;
    const isRightDisabled = indexRef.current >= sliderLengthRef.current - EPSILON;

    if (leftButtonRef.current && rightButtonRef.current) {
      leftButtonRef.current.disabled = isLeftDisabled;
      rightButtonRef.current.disabled = isRightDisabled;
    }
  }, []);

  const updateArrowButtonThrottled = useMemo(() => throttle(updateArrowButton, 200), [updateArrowButton]);
  useIsomorphicLayoutEffect(() => updateArrowButton(), [updateArrowButton]);
  useIsomorphicLayoutEffect(() => {
    if (buttonsRef.current) {
      buttonsRef.current.setAttribute('data-is-hydrated', 'true');
    }
  }, []);

  const updateItem = useCallback(
    (index: number) => {
      indexRef.current = index;

      // Update Wrapper
      const translate = Math.min(indexRef.current * itemWidthRef.current, wrapperWidthRef.current);
      const transform = `translate(${(-translate).toFixed(2)}px)`;

      if (wrapperRef.current) {
        /*
          const operatingSystem = useSelector(variablesSelector).variables?.operatingSystem;
          const isMobileSafari =
            (operatingSystem === 'ios' || operatingSystem === 'macos') && (isServer() || navigator.vendor.match(/apple/i));
         */
        /*
          const isWebAnimationSupported = !!wrapperRef.current.animate;
          if (isWebAnimationSupported && isMobileSafari) {
            // Workaround for safari rAF 60fps cap issue
            //
            // > Sharply targets the iPad / iPhone, and forcefully increases the animation's framerate
            // > See Also: https://bugs.webkit.org/show_bug.cgi?id=173434
            // >
            // > It can be replaced with 33.33333ms transition to linearly interpolate between frames,
            // > but it causes janks for non-120Hz devices

            try {
              const animation = wrapperRef.current.animate({ transform }, { duration: 33.33333, fill: 'forwards' });
              animation.commitStyles();
            } catch {
              // Ignore animate, as safari has partial support for implicit keyframe
            }
          }
        */

        // Safari also has buggy Web Animation API Implementation..!!
        // just waiting for the 60fps cap is removed...
        wrapperRef.current.style.transform = transform;
      }

      // Update ArrowButton
      updateArrowButtonThrottled();
    },
    [updateArrowButtonThrottled],
  );

  /*
   * Handle Swipe & Tween
   */
  const eventParamsRef = useLatestRef(eventParams);
  const handleScrollEvent = useMemo(
    () =>
      debounce(() => {
        if (eventParamsRef.current) {
          sendScrollEvent(eventParamsRef.current.screenName, 'section', eventParamsRef.current.params);
        }
      }, 500),
    [eventParamsRef],
  );

  const updateIsSwiping = useCallback(() => {}, []);
  const updateItemWithEvent = useCallback(
    (index: number, context: SwipeTweenContext) => {
      if (context.tag.name === 'swipe') {
        handleScrollEvent();
      }

      updateItem(index);
    },
    [updateItem, handleScrollEvent],
  );

  const tweenSpace = useMemo(() => createSliderTweenSpace(sliderLengthRef, maxSwipeRef), []);
  const tweenAnimation = useCallback((velocity: number) => {
    const speed = Math.abs(velocity);
    if (indexRef.current > sliderLengthRef.current || indexRef.current < 0) {
      // Overscroll
      return {
        easing: easeOutQuad,
        duration: 360,
      };
    }

    return {
      easing: speed > 0 ? easeOutCubic : easeInOutQuad,
      duration: Math.max(360 - 120 * speed, 240),
    };
  }, []);

  const { swipeRef, updateIndex } = useSwipeWithTween(
    { updateItem: updateItemWithEvent, updateIsSwiping },
    { itemWidth: itemWidthRef, maxSwipeItem: maxSwipeRef },
    { animation: tweenAnimation, space: tweenSpace },
  );

  /*
   * Initialize
   */
  useEffect(() => {
    const onInitialize = () => {
      const frame = frameRef.current;
      const wrapper = wrapperRef.current;
      const firstItem = wrapper?.firstElementChild;

      if (frame && wrapper && firstItem) {
        // Basic Metrics
        frameWidthRef.current = frame.getBoundingClientRect().width;
        wrapperWidthRef.current = wrapper.getBoundingClientRect().width;
        itemWidthRef.current = firstItem.getBoundingClientRect().width;

        // Derived Metrics
        itemPerFrameRef.current = Math.max(1, Math.floor(frameWidthRef.current / itemWidthRef.current));
        maxSwipeRef.current = itemPerFrameRef.current;

        const remainderSize =
          itemWidthRef.current < frameWidthRef.current
            ? (frameWidthRef.current % itemWidthRef.current) / itemWidthRef.current
            : 0;

        sliderLengthRef.current = itemLength - itemPerFrameRef.current - remainderSize;

        if (!limitScrollAreaToRightSide) {
          sliderLengthRef.current =
            maxSwipeRef.current === 1 ? Math.ceil(sliderLengthRef.current) : sliderLengthRef.current;
        }

        updateIndex(Math.max(0, Math.min(Math.round(indexRef.current), sliderLengthRef.current)));
      }
    };

    onInitialize();

    const onResize = debounce(onInitialize, 400);

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [itemLength, updateIndex, limitScrollAreaToRightSide]);

  /*
   * Render
   */
  const handleArrowButtonEvent = useCallback(
    (direction: number) => {
      if (eventParams) {
        sendClickEvent(eventParams.screenName, direction === -1 ? 'scroll_left' : 'scroll_right', eventParams.params);
      }
    },
    [eventParams],
  );

  const onClickLeft = useCallback(() => {
    handleArrowButtonEvent(-1);
    updateIndex(Math.max(Math.round(indexRef.current) - itemPerFrameRef.current, 0));
  }, [handleArrowButtonEvent, updateIndex]);

  const onClickRight = useCallback(() => {
    handleArrowButtonEvent(1);
    updateIndex(Math.min(Math.round(indexRef.current) + itemPerFrameRef.current, sliderLengthRef.current));
  }, [handleArrowButtonEvent, updateIndex]);

  return (
    <div css={styles.sliderContainerStyle} className={className}>
      <div ref={frameRef} css={styles.slideFrameStyle}>
        <div ref={swipeRef}>
          <ul ref={wrapperRef} css={[styles.listWrapperStyle, contentContainerCss]}>
            {children}
          </ul>
        </div>
      </div>
      <div css={[styles.arrowContainerStyle, arrowContainerCss]} ref={buttonsRef} data-is-hydrated="false">
        <ArrowButton direction="left" label="이전" onClick={onClickLeft} ref={leftButtonRef} />
        <ArrowButton direction="right" label="다음" onClick={onClickRight} ref={rightButtonRef} />
      </div>
    </div>
  );
};
