import { useSectionTrackingDataContext } from '../SectionTrackingDataContextProvider/SectionTrackingDataContextProvider';
import { Slider } from '../Slider';
import * as styles from './GridOrderedLayoutSection.styles';

const DEFAULT_ROW_COUNT = 3;

type GridOrderedLayoutSectionItemType = {
  id: number | string;
};

interface GridOrderedLayoutSectionProps<T> {
  items: T[];
  renderItem: (props: { item: T; index: number }) => ReactJSX.Element | null;
  rowCount?: number;
}

function convertArrayToMatrix<T>(arr: T[], per: number) {
  const matrix: T[][] = [];
  let key = -1;
  for (let i = 0; i < arr.length; i += 1) {
    if (i % per === 0) {
      key += 1;
      matrix[key] = [];
    }
    matrix[key].push(arr[i]);
  }

  return matrix;
}

/**
 * @description Grid형태로 렌더링 하는 섹션 컴포넌트
 * @param {GridOrderedLayoutSectionItemType[]} items item은 필수적으로 id를 가지고 있어야 한다. 공용 컴포넌트이기 때문에 id로 통일하려 함.
 * @param {(props: { item: T; index: number }) => ReactJSX.Element} props.renderItem item을 rendering 할 함수
 * @param {number} props.rowCount 한 row에 들어갈 item의 개수, default: 3
 * @example
 * const MySection = () => <GridOrderedLayoutSection items={items} renderItem={renderItem} />;
 */

export const GridOrderedLayoutSection = <T extends GridOrderedLayoutSectionItemType>({
  items,
  renderItem,
  rowCount = DEFAULT_ROW_COUNT,
}: GridOrderedLayoutSectionProps<T>): ReactJSX.Element => {
  const itemMatrix = convertArrayToMatrix(items, rowCount);
  const sectionTrackingData = useSectionTrackingDataContext();
  return (
    <Slider arrowContainerCss={styles.arrowContainerStyle} eventParams={sectionTrackingData}>
      {itemMatrix.map((itemArray, columnIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={columnIndex}>
          <ol css={styles.resetOlStyle}>
            {itemArray.map((item, rowIndex) => (
              <li key={item.id} css={styles.itemStyle}>
                {renderItem({ item, index: columnIndex * rowCount + rowIndex })}
              </li>
            ))}
          </ol>
        </li>
      ))}
    </Slider>
  );
};
