import { BreakPoint } from '@ridi-web/design-system/Styles';
import { PropsWithChildren } from 'react';

import { BookPreset } from '@/components/common/Book';

import { GenreHome3InfoPreset, GenreHomeBookItemProps, GenreHomeBookItemRenderer } from '../GenreHomeBook';
import { ListLayoutSection, ListLayoutSection3InfoItemsSkeleton } from '../ListLayoutSection';
import * as styles from './RankingBook.styles';

const Rank = ({ children }: PropsWithChildren) => (
  <div css={styles.rankStyle}>
    <svg css={styles.svgStyle} width={120} height={120} viewBox="0 -120 120 120">
      <text x="10" y="-10" css={styles.strokeStyle}>
        {children}
      </text>
      <text x="10" y="-10">
        {children}
      </text>
    </svg>
  </div>
);

/**
 * 베스트셀러와 실시간랭킹에 쓰이는 장르홈 실험용 북 컴포넌트
 * @see https://www.notion.so/ridi/UI-d3575ccfc4ee4a2eb1fe45b62f8a2472?pvs=4
 */
const RankingBookBasePreset: BookPreset = components => {
  const presetComponents = { ...components };

  presetComponents.BookBadgeRenderer = presetComponents.BookNothing;
  presetComponents.BookMetadataRenderer = presetComponents.BookMetadataRenderer.addStyle(styles.metadataStyle);
  presetComponents.BookRenderer = presetComponents.BookRenderer.addResponsiveOption('metadataMargin', {
    value: 20,
    greaterThan: BreakPoint.DesktopSmall,
  }).addResponsiveOption('metadataMargin', { value: 16, orBelow: BreakPoint.DesktopSmall });

  return presetComponents;
};

export const RankingBookPreset = [...GenreHome3InfoPreset, RankingBookBasePreset];
export const RankingBookItemRenderer = (props: GenreHomeBookItemProps) => {
  const { index } = props;

  return (
    <GenreHomeBookItemRenderer {...props}>
      <Rank>{index + 1}</Rank>
    </GenreHomeBookItemRenderer>
  );
};
export const RankingLayoutSection = ListLayoutSection;
export const RankingLayoutSectionContentSkeleton = ListLayoutSection3InfoItemsSkeleton;
